import type { z } from "zod";
import { useForm, useFieldArray } from "vee-validate";
import { toTypedSchema } from "@vee-validate/zod";
import { computed, ref, toRef, watch, type MaybeRef } from "vue";

type DeepPartial<T> = T extends object
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : T;

// type Paths<T> = T extends object
//   ? {
//       [K in keyof T]: `${Exclude<K, symbol>}${"" | `.${Paths<T[K]>}`}`;
//     }[keyof T]
//   : never;

type Leaves<T> = T extends object
  ? {
      [K in keyof T]: `${Exclude<K, symbol>}${Leaves<T[K]> extends never
        ? ""
        : `.${Leaves<T[K]>}`}`;
    }[keyof T]
  : never;

export type BindInput<T> = {
  name: string;
  errorMessage?: string;
  modelValue: T;
  "onUpdate:modelValue": (value: T) => void;
};
const currentFormErrors = ref<Record<string, string | undefined>>({});
const currentFormDisableSubmit = ref<boolean | undefined>();

export const useZodForm = <Schema extends z.ZodTypeAny>(
  schema: Schema,
  options?: {
    initialValues?: DeepPartial<z.infer<Schema>>;
    disabled?: boolean;
    showErrors?: MaybeRef<boolean>;
  }
) => {
  const validationSchema = toTypedSchema(schema);
  const showErrors = toRef(options?.showErrors);

  const {
    handleSubmit,
    setFieldError,
    setFieldValue,
    values,
    errors,
    meta,
    resetForm,
    isSubmitting,
    setValues,
    defineField,
    setFieldTouched,
    isFieldTouched,
    submitCount,
    submitForm,
  } = useForm({
    validationSchema,
    initialValues: options?.initialValues,
    validateOnMount: false,
    keepValuesOnUnmount: false,
  });

  // computed
  const disableSubmit = computed(
    () => submitCount.value > 0 && !meta.value.valid
  );

  // watchers
  watch(
    errors,
    () => {
      if (process.env.NODE_ENV === "development") {
        console.debug(errors.value);
      }

      if (options?.disabled !== true) {
        currentFormErrors.value = errors.value;
      }
    },
    { immediate: true }
  );

  watch(
    disableSubmit,
    () => {
      if (options?.disabled !== true) {
        currentFormDisableSubmit.value = disableSubmit.value;
      }
    },
    { immediate: true }
  );

  const bindInput = <T extends Leaves<z.infer<Schema>>>(
    name: T,
    {
      valueCasting,
    }: {
      valueCasting?: "number";
    } = {}
  ) => {
    return {
      name,
      modelValue: name
        .split(".")
        .reduce((prev, cur) => prev[cur], values) as any,

      errorMessage:
        // @ts-expect-error
        showErrors.value || isFieldTouched(name) || disableSubmit.value
          ? // @ts-expect-error
            errors.value[name]
          : undefined,
      // @ts-expect-error
      "on-blur": setFieldTouched(name, true),

      "onUpdate:modelValue": (value: any) => {
        if (valueCasting === "number") {
          value = Number(value);
        }

        // @ts-expect-error
        setFieldValue(name, value);
      },
    };
  };

  return {
    handleSubmit,
    setFieldError,
    setFieldValue,
    resetForm,
    defineField,
    bindInput,
    setValues,
    useFieldArray,
    submitForm,

    values,
    errors,
    meta,
    isSubmitting,
    disableSubmit,
    currentFormDisableSubmit,
  };
};

export const useFormErrors = () => {
  // todo: make it type safe somehow
  const getErrorMessage = (name: string) => {
    if (currentFormDisableSubmit.value !== true) {
      return undefined;
    }
    return currentFormErrors.value[name];
  };

  return { getErrorMessage, currentFormErrors, currentFormDisableSubmit };
};
